*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[-20\%\] {
  margin-top: -20%;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-\[15vh\] {
  height: 15vh;
}

.h-\[18vh\] {
  height: 18vh;
}

.h-\[20vh\] {
  height: 20vh;
}

.h-\[30vh\] {
  height: 30vh;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-full {
  height: 100%;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[40vh\] {
  max-height: 40vh;
}

.max-h-\[700px\] {
  max-height: 700px;
}

.max-h-full {
  max-height: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-5 {
  width: 1.25rem;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[40vw\] {
  width: 40vw;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: max-content;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[1\.3rem\] {
  border-radius: 1.3rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-fgGreen {
  --tw-bg-opacity: 1;
  background-color: rgb(123 174 19 / var(--tw-bg-opacity));
}

.bg-fgPink {
  --tw-bg-opacity: 1;
  background-color: rgb(255 5 175 / var(--tw-bg-opacity));
}

.bg-fgPurple {
  --tw-bg-opacity: 1;
  background-color: rgb(101 0 182 / var(--tw-bg-opacity));
}

.bg-fgPurple2 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 0 142 / var(--tw-bg-opacity));
}

.bg-fgYellow {
  --tw-bg-opacity: 1;
  background-color: rgb(250 218 40 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-clash-display-bold {
  font-family: ClashDisplay-Bold, sans-serif;
}

.font-clash-display-medium {
  font-family: ClashDisplay-Medium, sans-serif;
}

.font-clash-display-semibold {
  font-family: ClashDisplay-Semibold, sans-serif;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.leading-5 {
  line-height: 1.25rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-fgGreen {
  --tw-text-opacity: 1;
  color: rgb(123 174 19 / var(--tw-text-opacity));
}

.text-fgPink {
  --tw-text-opacity: 1;
  color: rgb(255 5 175 / var(--tw-text-opacity));
}

.text-fgPurple {
  --tw-text-opacity: 1;
  color: rgb(101 0 182 / var(--tw-text-opacity));
}

.text-fgYellow {
  --tw-text-opacity: 1;
  color: rgb(250 218 40 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-70 {
  opacity: .7;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.container {
  overflow-x: hidden;
}

body {
  background-color: #fbd800;
  background-image: linear-gradient(to right, #e6b91b 3px, #0000 3px), linear-gradient(#e6b91b 3px, #0000 3px), radial-gradient(circle, #e6b91b 10%, #0000 10%), radial-gradient(circle, #e6b91b 10%, #0000 10%);
  background-position: 0 0, 0 0, -10px -10px, 10px 10px;
  background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;
  font-family: ClashDisplay-Bold, sans-serif;
}

.text-stroke-sm {
  -webkit-text-stroke: 1px black;
}

@media (width >= 768px) {
  .text-stroke-lg {
    -webkit-text-stroke: 3px black;
  }

  .anim-text-stroke {
    -webkit-text-stroke: 2px black;
  }
}

.text-drop-shadow {
  text-shadow: 5px 5px #6500b6;
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(20%);
  }

  to {
    transform: translateX(-20%);
  }
}

@keyframes slideLeftToRight {
  from {
    transform: translateX(-20%);
  }

  to {
    transform: translateX(20%);
  }
}

.animate-slide-right-to-left {
  animation: 60s linear infinite slideRightToLeft;
}

.animate-slide-left-to-right {
  animation: 60s linear infinite slideLeftToRight;
}

@media (width >= 768px) {
  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:ml-28 {
    margin-left: 7rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:h-\[40vh\] {
    height: 40vh;
  }

  .md\:h-\[50vh\] {
    height: 50vh;
  }

  .md\:h-\[5rem\] {
    height: 5rem;
  }

  .md\:h-\[60\%\] {
    height: 60%;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:max-h-\[550px\] {
    max-height: 550px;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-\[40rem\] {
    width: 40rem;
  }

  .md\:w-\[5rem\] {
    width: 5rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:max-w-\[115rem\] {
    max-width: 115rem;
  }

  .md\:max-w-\[40\%\] {
    max-width: 40%;
  }

  .md\:max-w-\[50\%\] {
    max-width: 50%;
  }

  .md\:max-w-\[90\%\] {
    max-width: 90%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:border-4 {
    border-width: 4px;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:mr-40 {
    margin-right: 10rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-\[80rem\] {
    width: 80rem;
  }

  .lg\:max-w-\[110rem\] {
    max-width: 110rem;
  }

  .lg\:max-w-\[90rem\] {
    max-width: 90rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:leading-5 {
    line-height: 1.25rem;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #6b46c1;
}

body {
  scrollbar-width: thin;
  scrollbar-color: white #6b46c1;
}

::-webkit-scrollbar-track-piece {
  background-color: #6b46c1;
  border-radius: 10px;
  margin-top: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #3f0f5f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}

::-webkit-scrollbar-thumb:active {
  background-color: #fff;
}

/*# sourceMappingURL=index.77cc94b4.css.map */
