/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  /* Hide horizontal overflow */
  overflow-x: hidden;
}

body {
  /* Set the background color of the <body> element to the bgYellow color */
  background-color: #FBD800;
  
  font-family: 'ClashDisplay-Bold', sans-serif;
  
  /* Create a grid pattern using the bgGrid color */
  background-image: linear-gradient(to right, #E6B91B 3px, transparent 3px),
                    linear-gradient(to bottom, #E6B91B 3px, transparent 3px),
                    radial-gradient(circle, #E6B91B 10%, transparent 10%),
                    radial-gradient(circle, #E6B91B 10%, transparent 10%);
  background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;
  background-position: 0 0, 0 0, -10px -10px, 10px 10px;
}

.text-stroke-sm {
    -webkit-text-stroke: 1px black;
}

/* Custom media query for larger screens */
@media (min-width: 768px) {
    .text-stroke-lg {
        -webkit-text-stroke: 3px black;
    }
    .anim-text-stroke {
        -webkit-text-stroke: 2px black;
    }
}

.text-drop-shadow {
    text-shadow: 5px 5px 0 #6500B6;
}

@keyframes slideRightToLeft {
    from {
        transform: translateX(20%);
    }
    to {
        transform: translateX(-20%);
    }
}

@keyframes slideLeftToRight {
    from {
        transform: translateX(-20%);
    }
    to {
        transform: translateX(20%);
    }
}

.animate-slide-right-to-left {
    animation: slideRightToLeft 60s linear infinite;
}

.animate-slide-left-to-right {
    animation: slideLeftToRight 60s linear infinite;
}

