/* custom-scrollbar.css */

/* WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #6b46c1;
  }
  
  /* Firefox */
  body {
    scrollbar-width: thin;
    scrollbar-color: white #6b46c1;
  }
  
  /* Additional styling for the scrollbar track */
  ::-webkit-scrollbar-track-piece {
    background-color: #6b46c1;
    border-radius: 10px; /* Rounded edges for scrollbar track */
    margin-top: 10px; /* Add space on top of the scrollbar track */
  }
  
  /* Optional: Hide the scrollbar buttons */
  ::-webkit-scrollbar-button {
    display: none;
  }
  
  /* Optional: Style the scrollbar background */
  ::-webkit-scrollbar-track {
    background-color: #3f0f5f;
    border-radius: 10px; /* Rounded edges for scrollbar background */
  }
  
  /* Optional: Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
  }
  
  /* Optional: Remove hover effect on scrollbar thumb */
  ::-webkit-scrollbar-thumb:hover {
    background-color: white;
  }
  
  /* Optional: Style the scrollbar thumb when grabbed/dragged */
  ::-webkit-scrollbar-thumb:active {
    background-color: white;
  }
  